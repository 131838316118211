import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { useMutation, useQuery } from "@apollo/client";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import {
  GET_SUPPORTING_PAYMENT_METHOD,
  GIVE_SUPPORT_MUTATION,
} from "apps/PublicPage/graphql";

import { ElementsProvider } from "providers/StripeProvider";
import {
  CardHeader,
  CardInner,
  CardTitle,
  Col,
  Form,
  FormItemLabel,
  FormItemUIOnly,
  Row,
  Button,
  A,
  Skeleton,
  FormItemError,
  Spacer,
} from "atoms";
import CardDetails from "./payment/CardDetails";
import { TRUE } from "consts";
import { FaChevronLeft } from "icons";
import { default as VisaIcon } from "icons/Visa";
import { GENERAL_TIPS_THANK_YOU, MESSAGE_TIPS_THANK_YOU } from "graphql-api";

const SGiveSupport = styled.div``;
const SavedCardRow = styled.div`
  padding: 10px 0 13px;
`;
const CardNumberInfo = styled.div``;
const VisaIconContainer = styled.div`
  width: 22px;
  padding-right: 5px;
  padding-bottom: 2px;
  box-sizing: content-box;
  color: var(--color-grey-lighter);
  line-height: 0;
`;
const Terms = styled.div`
  margin-top: 20px;
  font-size: 12px;
  color: var(--color-content-grey);
  ${A}:not(:hover) {
    color: var(--color-content-grey);
  }
`;

const GiveSupportForm = ({
  amount,
  showId,
  messageId,
  onSuccess,
  onError,
}) => {
  const [isConfirmPaymentBusy, setIsConfirmPaymentBusy] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [cardDetailsComplete, setCardDetailsComplete] = useState(false);
  const [cardDetailsError, setCardDetailsError] = useState(null);
  const [stripeCardIncompleteError, setStripeCardIncompleteError] =
    useState(false);
  const [isInChangeCard, setIsInChangeCard] = useState(false);

  const { data: supportingPaymentMethodData, loading } = useQuery(
    GET_SUPPORTING_PAYMENT_METHOD,
    {
      fetchPolicy: "network-only",
    }
  );
  const supportingPaymentMethod = useMemo(
    () => supportingPaymentMethodData?.getSupportingPaymentMethod,
    [supportingPaymentMethodData]
  );
  const { brand, last4 } = useMemo(
    () => supportingPaymentMethod || {},
    [supportingPaymentMethod]
  );
  const hasSavedCard = useMemo(() => !!last4, [last4]);
  const isInSavedCard = useMemo(
    () => hasSavedCard && !isInChangeCard,
    [hasSavedCard, isInChangeCard]
  );

  const [stripeApiError, setStripeApiError] = useState(null);
  const [form] = Form.useForm();
  const [isTokenBusy, setIsTokenBusy] = useState(false);

  const [triggerGiveSupport, { loading: isGiveSupportBusy }] = useMutation(
    GIVE_SUPPORT_MUTATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: messageId ? MESSAGE_TIPS_THANK_YOU : GENERAL_TIPS_THANK_YOU,
          variables: { showId },
        },
      ],
      onCompleted({ giveSupport: { error, success, message } }) {
        if (success === TRUE) {
          setIsConfirmPaymentBusy(true);
          handleAfterSuccess({ message });
        } else {
          setStripeApiError({ message: error });
        }
      },
    }
  );

  const handleAfterSuccess = ({ message }) => {
    onSuccess && onSuccess({ message });
    setIsConfirmPaymentBusy(false);
  };
  const handleFinish = async () => {
    try {
      if (isInSavedCard) {
        triggerGiveSupport({
          variables: {
            showId,
            cents: amount * 100,
            ...(messageId ? { messageId } : {}),
          },
        });
        return;
      }
      if (!cardDetailsComplete) {
        !cardDetailsError && setStripeCardIncompleteError(true);
        return;
      }
      const cardElement = elements.getElement(CardElement);
      setIsTokenBusy(true);
      const {
        token: { id: tokenId },
      } = await stripe.createToken(cardElement);
      triggerGiveSupport({
        variables: {
          showId,
          cents: amount * 100,
          token: tokenId,
          ...(messageId ? { messageId } : {}),
        },
      });
      setIsTokenBusy(false);
    } catch (e) {
      onError && onError();
    }
  };

  const handleCardDetailsChange = (event) => {
    setStripeApiError(null);
    setStripeCardIncompleteError(false);
    setCardDetailsComplete(event.complete);
    setCardDetailsError(event.error);
  };

  if (loading) {
    return <Skeleton />;
  }

  return (
    <Form onFinish={handleFinish} initialValues={{}} form={form}>
      {isInSavedCard && (
        <FormItemUIOnly>
          <FormItemLabel>Payment Info</FormItemLabel>
          <SavedCardRow>
            <Row align="middle">
              <Col>
                <VisaIconContainer>
                  <VisaIcon />
                </VisaIconContainer>
              </Col>
              <Col>
                <CardNumberInfo>
                  {brand} xxxx {last4}
                </CardNumberInfo>
              </Col>
              <Spacer />
              <Col>
                <A onClick={() => setIsInChangeCard(true)}>change card</A>
              </Col>
            </Row>
          </SavedCardRow>
          {stripeApiError && (
            <FormItemError>{stripeApiError.message}</FormItemError>
          )}
        </FormItemUIOnly>
      )}
      {!isInSavedCard && (
        <FormItemUIOnly>
          <FormItemLabel>Payment Info</FormItemLabel>
          <CardDetails onChange={handleCardDetailsChange} />
          {stripeApiError && (
            <FormItemError>{stripeApiError.message}</FormItemError>
          )}
          {stripeCardIncompleteError && (
            <FormItemError>All fields are required</FormItemError>
          )}
        </FormItemUIOnly>
      )}
      <Button
        htmlType="submit"
        block
        className="mt-3"
        loading={isTokenBusy || isGiveSupportBusy || isConfirmPaymentBusy}
      >
        give support
      </Button>
    </Form>
  );
};

const AmountInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  color: var(--color-green);
`;
const AmountInput = styled.input`
  width: 100px;
  font-size: 18px;
  padding: 12px 12px;
  max-width: 200px;
  margin-right: -30px;
  border: none;
  outline: none;
  &:focus,
  &:focus-visible {
    border: none;
    outline: none;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

const SCustomSupportAmount = styled.div`
  text-align: center;
`;
const CustomSupportAmount = ({ onFinish }) => {
  const [amount, setAmount] = useState("");
  const inputWidth = useMemo(
    () => `calc(${(amount.length + 1) * 0.7}em + 30px)`,
    [amount]
  );
  const amountInt = useMemo(() => Number(amount.replace(",", "")), [amount]);
  const [validateError, setValidateError] = useState(null);

  const triggerValidator = () => {
    if (amountInt > 2000) {
      setValidateError("please limit each donation to $2,000");
    } else {
      setValidateError(null);
      onFinish && onFinish(amountInt);
    }
  };

  const handleChange = (e) => {
    const v = e.target.value.replace(/[^\d]/g, "").slice(0, 4);
    let vNumber = v ? Number(v) : -1;
    if (vNumber <= 2000) {
      setValidateError(false);
    }
    const formattedV =
      vNumber > -1
        ? vNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "";
    setAmount(formattedV);
  };
  const handleFinish = () => {
    triggerValidator();
  };
  return (
    <SCustomSupportAmount>
      <CardTitle className="mb-4">Your Support Amount</CardTitle>
      {validateError && (
        <FormItemError css="margin-top: -20px; margin-bottom: 20px;">
          {"please limit each donation to $2,000"}
        </FormItemError>
      )}
      <AmountInputContainer className="mb-4">
        $&nbsp;
        <AmountInput
          type="string"
          value={amount}
          autoFocus
          onChange={handleChange}
          style={{ width: `100px`, fontSize: `18px`, padding: `12px 12px 12px 0` }}
        />
      </AmountInputContainer>
      <Button block disabled={!amountInt} onClick={handleFinish}>
        Looks good
      </Button>
    </SCustomSupportAmount>
  );
};

const Amount = styled.div`
  font-size: 20px;
  color: var(--color-green);
`;
export const [SUPPORT, CUSTOM_AMOUNT] = ["SUPPORT", "CUSTOM_AMOUNT"];

const GiveSupport = ({
  isLoggedIn,
  amount,
  showId,
  messageId,
  initialStep,
  ...props
}) => {
  const [step, setStep] = useState(initialStep);
  const [theAmount, setAmount] = useState(amount);

  const handleCustomAmountFinish = (cAmount) => {
    setAmount(cAmount);
    setStep(SUPPORT);
  };

  return (
    <SGiveSupport>
      {step === SUPPORT && (
        <>
          <CardHeader>
            <A block onClick={() => setStep(CUSTOM_AMOUNT)} className="mb-1">
              <FaChevronLeft css="font-size: 13px;" /> edit amount
            </A>
            <Row align="middle">
              <Col flex="1 0 auto">
                <CardTitle>Your Support Amount</CardTitle>
              </Col>
              <Col>
                <Amount>${theAmount}</Amount>
              </Col>
            </Row>
          </CardHeader>
          <CardInner>
            <ElementsProvider>
              <GiveSupportForm
                isLoggedIn={isLoggedIn}
                amount={theAmount}
                showId={showId}
                messageId={messageId}
                {...props}
              />
            </ElementsProvider>
          </CardInner>
        </>
      )}
      {step === CUSTOM_AMOUNT && (
        <CardInner>
          <div className="pt-4">
            <CustomSupportAmount onFinish={handleCustomAmountFinish} />
          </div>
        </CardInner>
      )}
    </SGiveSupport>
  );
};
GiveSupport.defaultProps = {
  initialStep: CUSTOM_AMOUNT,
  messageId: null,
};
export default GiveSupport;
