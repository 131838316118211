import React from 'react';
import styled from 'styled-components';

import PaymentSelection from 'components/PaymentSelection';
import { Show, ID, Message } from 'types';
import { Col, Row } from 'atoms';

const SAddTip = styled.div``;
const AddTipHeader = styled.div`
  margin-bottom: 15px;
`;
const AddATip = styled.div`
  font-weight: 500;
`;
const AllTipsGo = styled.div`
  color: var(--color-content-grey);
`;

type Props = {
  isLoggedIn: boolean
  show: Show
  messageId?: ID
  onSelect: () => void
  onSupport?: ({ show: Show, message: Message }) => void
}
const AddTip = (props: Props) => {
  const { show, messageId, isLoggedIn, onSelect, onSupport } = props;
  return (
    <SAddTip>
      <AddTipHeader>
        <Row justify="space-between">
          <Col>
            <AddATip>add a tip</AddATip>
          </Col>
          <Col>
            <AllTipsGo>all tips go to the show</AllTipsGo>
          </Col>
        </Row>
      </AddTipHeader>
      <PaymentSelection
        isLoggedIn={isLoggedIn}
        show={show}
        messageId={messageId}
        small
        onSelect={onSelect}
        onSupport={onSupport}
      />
    </SAddTip>
  )
}

export default AddTip;