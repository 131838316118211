import { message } from "atoms";
import React from "react";
import styled from "styled-components";

const SFanCopyClipboard = styled.div`
  border-radius: 3px;
  cursor: pointer;
  max-width: 240px;
`;

type Props = {
  children: React.ReactNode,
  copyContent: string
}
const FanCopyClipboard = (props: Props) => {
  const { copyContent } = props;
  const handleCopy = async () => {
    try {
        await navigator.clipboard.writeText(copyContent);
        message.success({ content: `Copied` });
    } catch (e) {
        message.error("Something went wrong, please try later");
    }
  }
  return (
    <SFanCopyClipboard onClick={handleCopy}>{props.children}</SFanCopyClipboard>
  )
}

export default FanCopyClipboard;
